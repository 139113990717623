import React, { useEffect } from 'react';
import { Link } from 'react-router-dom'
import CartComponent from '../../components/CartComponent'
import Menu from '../../components/Menu'
import { useAuth } from '../../context/AuthContext';
import { useProfile } from '../../context/ProfileContext'

const Header = () => {
    const { isAuthenticated } = useAuth();
    const { userInfo } = useProfile();
        // Log userInfo data
        useEffect(() => {
            console.log('User Info:', userInfo);
        }, [userInfo]); // Logs whenever userInfo changes
    return (
        <section className="as_header_wrapper">
            <div className="container">
                <div className="row">
                    <div className="col-lg-3 col-md-3 col-sm-4 col-xs-6">
                        <div className="as_logo">
                            <a href="/">
                                <img alt="" src="/assets/images/logo.svg" />
                            </a>
                        </div>
                    </div>
                    <div className="col-lg-9 col-md-9 col-sm-8 col-xs-6">
                        <div className="as_right_info">
                            <div className="as_menu_wrapper">
                                <span className="as_toggle">
                                    <img alt="" src="/assets/images/svg/menu.svg" />
                                </span>
                                <Menu />
                            </div>

                            <CartComponent />

                            <div className="as_user">
                                {!isAuthenticated ? (<a href="/login">Login</a> ) : (
                                    <a href="/profile">
                                        <img height="50px" width="50px" alt="" src={`${userInfo.full_profile_pic}` || "/assets/images/icon-5404125_1280.png"} />
                                        <span className="as_add_user">+</span>
                                    </a>
                                )}
                            </div>
                            {/* <div>
                                <div>Wallet</div>
                            <i class='fas fa-wallet'></i>
                            <span>{userInfo.wallet_balance}</span>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Header
