import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Helpers from '../Helpers/Helpers'
import Notification from '../Helpers/Notification'

const AstrologerCard = ({ astrologer, column = 4 }) => {
    const navigate = useNavigate();
    const helpers = Helpers()
    const initiateChat = async (astrologerId) => {
        const response = await helpers.httpRequest(`/chat/create`, 'POST', { astrologerId })
        Notification(response.status, response.message);
        // console.log(response.data._id);

        if (response.status === 'success') {
            navigate(`/chat/${response.data._id}`)
        }
    }
    return (
        <>
            <div className="as_product_box" key={astrologer._id}>
                <div className="as_product_img">
                    <img
                        className="img-responsive"
                        alt="Product Image"
                        src={`${astrologer.full_profile_pic}`}
                        style={{ height: "220px" }}
                    />
                    <ul>
                        <li>
                            {
                                <>
                                    <strong onClick={() => initiateChat(astrologer.id)} style={{ cursor: 'pointer' }}>
                                        <img alt="" src="/assets/images/chat.png" width="30px" height="30px" />
                                        <span>Start Chat</span>
                                    </strong>
                                </>

                            }
                        </li>
                    </ul>
                </div>
                <div className="as_product_detail">
                    <h4 className="as_subheading">{astrologer.name}</h4>
                    <h6>{astrologer.specialization ? astrologer.specialization : "-"}</h6>
                    <h6>{astrologer.language ? astrologer.language : "-"}</h6>
                    <h6>Exp: {astrologer.year_of_exp ? `${astrologer.year_of_exp} Years` : "0 Years"} </h6>
                    <h6>                     
                        <span className="as_price">
                            ₹ {astrologer.fees ? `${astrologer.fees}/min` : "0/min"}
                            <del>₹ {astrologer.delete_fees}</del>
                        </span>
                    </h6>

                </div>
            </div>

        </>
    )
}

export default AstrologerCard
